import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SelectRoom.css';
import { dbFQDN } from '../config';

const SelectRoom = () => {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();
  const selectedResortId = localStorage.getItem('selected_resort_id');  // Retrieve the resort ID

  useEffect(() => {
    if (selectedResortId) {
      fetch(`${dbFQDN}/di/v1/room/${selectedResortId}`)
        .then(response => response.json())
        .then(data => setRooms(data));
    }
  }, [selectedResortId]);

  const handleRoomSelect = (room) => {
    localStorage.setItem('selected_room', JSON.stringify(room));
    localStorage.setItem('selected_room_id', room.id);  // Save the room ID
    navigate('/review-and-save');
  };

  return (
    <div className="select-room-container" style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
      {/* Top navigation with back arrow, home button, and exit */}
      <div className="top-navigation" style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#0078ae', color: 'white', fontSize: '14px' }}>
        <div className="back-arrow" style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => window.history.back()}>
          <i className="fas fa-arrow-left"></i> Back
        </div>
        <div className="home-button" style={{ cursor: 'pointer', flexGrow: 1, textAlign: 'center' }} onClick={() => window.location.href = '/'}>
          <i className="fas fa-home"></i> Exit
        </div>
      </div>

      <h1 style={{ color: '#0078ae', marginBottom: '20px' }}>Select a Room</h1>
      
      <div className="rooms" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {rooms.map(room => (
          <div
            key={room.room_id}
            className="room-card"
            onClick={() => handleRoomSelect(room)}
            style={{
              margin: '10px',
              padding: '15px',
              border: '1px solid #0078ae',
              borderRadius: '10px',
              cursor: 'pointer',
              width: '200px',
              textAlign: 'center',
              backgroundColor: 'white',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <p style={{ margin: '0', fontWeight: 'bold' }}>{room.name}</p>
            <p style={{ margin: '0', color: '#555' }}>{room.type}</p>
            <p style={{ margin: '0', color: '#0078ae', fontWeight: 'bold' }}>${room.price}/night</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectRoom;
