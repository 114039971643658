// config.js
export const appName = 'DItinerary.com';
export const siteFQDN = 'https://ditinerary.com';
export const basePath = "OGERPVM";
export const defaultBackgroundColor = '#0078ae';
export const customerPortal = 'https://billing.stripe.com/p/login/test_14k6rvfg3d577lK4gg';
export const appVersion = '0_1_0';

export const dbFQDN = process.env.NODE_ENV === 'production'
  ? 'https://api.ditinerary.com'
  : 'https://api.ditinerary.com:8000';