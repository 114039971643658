// src/components/About.js
import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';

const About = () => {
    return (
        <div className="about-container">
            <div className="about-content">
                <Link to="/" className="back-button">Back</Link>
                <h1>About Ditinerary.com</h1>
                <p>
                    Welcome to Ditinerary.com, your go-to solution for planning and organizing your perfect Disney vacation. Our platform offers an intuitive and user-friendly way to create detailed itineraries, ensuring you make the most of your magical experience.
                </p>
                <h2>Our Mission</h2>
                <p>
                    Our mission is to simplify the planning process by providing all the tools you need in one place. Whether you're planning a family trip or a solo adventure, Ditinerary.com helps you customize your itinerary to match your unique preferences.
                </p>
                <h2>Why Choose Us?</h2>
                <ul>
                    <li>Easy-to-use interface designed with Disney fans in mind.</li>
                    <li>Comprehensive tools for creating and managing itineraries.</li>
                    <li>Personalized suggestions and tips to enhance your trip.</li>
                    <li>Access to a community of fellow Disney enthusiasts.</li>
                </ul>
                <h2>Contact Us</h2>
                <p>
                    Have any questions or need assistance? Feel free to reach out to us at <a href="mailto:support@ditinerary.com">support@ditinerary.com</a>. We're here to help!
                </p>
            </div>
        </div>
    );
};

export default About;
