import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Itinerary from './components/Itinerary';
import GettingStarted from './components/GettingStarted';
import AdultsChildren from './components/AdultsChildren';
import CheckInCheckOut from './components/CheckInCheckOut';
import SelectResort from './components/SelectResort';
import SelectRoom from './components/SelectRoom';
import ReviewAndSave from './components/ReviewAndSave';
import Login from './components/Login';
import About from './components/About';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Logout from './components/Logout';
import SubscriptionPage from './components/SubscriptionPage';
import Category from './components/Category';
import CategoryItem from './components/CategoryItem';
import Socialshare from './components/Socialshare';

import './App.css';

const App = () => {
  return (
    <Routes>
      {/* Login Route */}
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />

      {/* Public Routes */}
      <Route path="/about" element={<About />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/subscription" element={<SubscriptionPage />} />

      {/* Itinerary Routes */}
      <Route path="/itinerary/:itinerary_id" element={<Itinerary />} /> {/* Old URL format */}
      <Route path="/itinerary" element={<Itinerary />} />              {/* General Itinerary */}
      <Route path="/:itinerary_id" element={<Itinerary />} />         {/* New root URL with ID */}
      <Route path="/" element={<Itinerary />} />                      {/* Root URL for general Itinerary */}

      {/* Getting Started and other flow routes */}
      <Route path="/getting-started" element={<GettingStarted />} />
      <Route path="/adults-children" element={<AdultsChildren />} />
      <Route path="/check-in-check-out" element={<CheckInCheckOut />} />
      <Route path="/select-resort" element={<SelectResort />} />
      <Route path="/select-room" element={<SelectRoom />} />
      <Route path="/review-and-save" element={<ReviewAndSave />} />

      {/* Dynamic Category Routes */}
      <Route path="/park/:id" element={<CategoryItem />} />
      <Route path="/ride/:id" element={<CategoryItem />} />
      <Route path="/dining/:id" element={<CategoryItem />} />
      <Route path="/resort/:id" element={<CategoryItem />} />
      <Route path="/travel/:id" element={<CategoryItem />} />
      <Route path="/extra/:id" element={<CategoryItem />} />

      {/* Dynamic Category Routes */}
      <Route path="/park" element={<Category />} />
      <Route path="/ride" element={<Category />} />
      <Route path="/dining" element={<Category />} />
      <Route path="/resort" element={<Category />} />
      <Route path="/travel" element={<Category />} />
      <Route path="/extra" element={<Category />} />

      <Route path="/assets/images/social-share-image-2-360x360.webp" element={<Socialshare />} />
    </Routes>
  );
};

export default App;
