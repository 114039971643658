import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdultsChildren.css';

const AdultsChildren = () => {
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [childrenAges, setChildrenAges] = useState([]);
  const [parkDays, setParkDays] = useState(1); // New state for park days
  const navigate = useNavigate();

  const handleChildrenChange = (e) => {
    const numChildren = parseInt(e.target.value, 10);
    setChildren(numChildren);
    setChildrenAges(new Array(numChildren).fill(''));
  };

  const handleAgeChange = (index, age) => {
    const updatedAges = [...childrenAges];
    updatedAges[index] = age;
    setChildrenAges(updatedAges);
  };

  const handleNext = () => {
    const childrenAgesJson = JSON.stringify(childrenAges);
    localStorage.setItem('adults', adults);
    localStorage.setItem('children', children);
    localStorage.setItem('children_ages', childrenAgesJson);
    localStorage.setItem('parkDays', parkDays); // Save parkDays to localStorage
    navigate('/check-in-check-out');
  };

  return (
    <div className="adults-children-container" style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
      {/* Top navigation with back arrow, home button, and exit */}
      <div className="top-navigation" style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#0078ae', color: 'white', fontSize: '14px' }}>
        <div className="back-arrow" style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => window.history.back()}>
          <i className="fas fa-arrow-left"></i> Back
        </div>
        <div className="home-button" style={{ cursor: 'pointer', flexGrow: 1, textAlign: 'center' }} onClick={() => window.location.href = '/'}>
          <i className="fas fa-home"></i> Exit
        </div>
      </div>

      <h1 style={{ color: '#0078ae', marginBottom: '20px' }}>How many adults and children?</h1>
      
      <label style={{ display: 'block', marginBottom: '10px' }}>
        Adults:
        <input type="number" value={adults} onChange={(e) => setAdults(e.target.value)} min="1" style={{ marginLeft: '10px', padding: '5px', borderRadius: '5px' }} />
      </label>

      <label style={{ display: 'block', marginBottom: '10px' }}>
        Children:
        <input type="number" value={children} onChange={handleChildrenChange} min="0" style={{ marginLeft: '10px', padding: '5px', borderRadius: '5px' }} />
      </label>

      {childrenAges.map((age, index) => (
        <label key={index} style={{ display: 'block', marginBottom: '10px' }}>
          Age of child {index + 1}:
          <input type="number" value={age} onChange={(e) => handleAgeChange(index, e.target.value)} min="0" style={{ marginLeft: '10px', padding: '5px', borderRadius: '5px' }} />
        </label>
      ))}

      {/* New input for park days */}
      <label style={{ display: 'block', marginBottom: '20px' }}>
        Days visiting parks:
        <input type="number" value={parkDays} onChange={(e) => setParkDays(Math.min(10, Math.max(1, e.target.value)))} min="1" max="10" style={{ marginLeft: '10px', padding: '5px', borderRadius: '5px' }} />
      </label>

      <button onClick={handleNext} style={{ padding: '10px 20px', backgroundColor: '#0078ae', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
        Next
      </button>
    </div>
  );
};

export default AdultsChildren;
