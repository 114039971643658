import React, { useEffect } from 'react';

const SubscriptionPage = () => {
  useEffect(() => {
    // Retrieve the user's email from token or cookie
    const getUserEmail = () => {
      // Example: Fetch email from a token stored in localStorage or cookies
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        try {
          const parsedUser = JSON.parse(storedUser);
          return parsedUser.email;
        } catch (error) {
          console.error("Error parsing user data:", error);
          return null;
        }
      }
      return null;
    };

    const customerEmail = getUserEmail();

    // Dynamically load the Stripe pricing table script after the component mounts
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    // Inject the Stripe Pricing Table with the customer's email
    const pricingTableHTML = `
      <stripe-pricing-table
        pricing-table-id="prctbl_1PsbhBP6Dhc1Dsl5a0kMRLBG"
        publishable-key="pk_live_51PrXCQP6Dhc1Dsl5I2En9avdBnh3wvaMbJ6DKQXdIzLAlyfOAZOo2jGoRkOOi05c2J7WMmG3ER9TmC9UeT81PHso00FZ8KRNzR"
        ${customerEmail ? `customer-email="${customerEmail}"` : ""}
      ></stripe-pricing-table>
    `;

    const pricingTableContainer = document.createElement('div');
    pricingTableContainer.innerHTML = pricingTableHTML;
    document.body.appendChild(pricingTableContainer);

    return () => {
      // Clean up the script and pricing table when the component unmounts
      document.body.removeChild(script);
      document.body.removeChild(pricingTableContainer);
    };
  }, []);

  return (
    <div>
      <h1>Choose Your Subscription Plan</h1>
    </div>
  );
};

export default SubscriptionPage;
