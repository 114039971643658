import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './CheckInCheckOut.css';

const CheckInCheckOut = () => {
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [dateError, setDateError] = useState('');
  const [dateDistance, setDateDistance] = useState(0); // Default date distance of 4 days
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (checkIn && checkOut) {
      const inDate = new Date(checkIn);
      const outDate = new Date(checkOut);
      const dateDifference = (outDate - inDate) / (1000 * 60 * 60 * 24);

      if (dateDifference > 10) {
        setDateError('Your stay cannot be longer than 10 days. Please adjust your dates.');
      } else if (dateDifference < 0) {
        // Adjust the checkOut date based on the previous date distance if it's negative
        const newCheckOut = calculateCheckOutDate(checkIn, dateDistance);
        setCheckOut(newCheckOut);
      } else {
        setDateDistance(dateDifference);
        setDateError('');
      }
    }
  }, [checkIn, checkOut, dateDistance]);

  const destinationName = localStorage.getItem('destinationName');

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start) {
      const formattedCheckIn = start.toISOString().split('T')[0];
      setCheckIn(formattedCheckIn);
    }

    if (end) {
      const formattedCheckOut = end.toISOString().split('T')[0];
      setCheckOut(formattedCheckOut);
    }
  };

  const calculateCheckOutDate = (checkInDate, daysToAdd = 4) => {
    const checkIn = new Date(checkInDate);
    checkIn.setDate(checkIn.getDate() + daysToAdd); // Add the specified days to check-in date
    return checkIn.toISOString().split('T')[0]; // Return date in 'yyyy-MM-dd' format
  };

  const handleNext = () => {
    if (!dateError) {
      localStorage.setItem('check_in', checkIn);
      localStorage.setItem('check_out', checkOut);
      navigate('/review-and-save');
    }
  };

  return (
    <div className="check-in-check-out-container" style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
      {/* Top navigation with back arrow, home button, and exit */}
      <div className="top-navigation" style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#0078ae', color: 'white', fontSize: '14px' }}>
        <div className="back-arrow" style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => window.history.back()}>
          <i className="fas fa-arrow-left"></i> Back
        </div>
        <div className="home-button" style={{ cursor: 'pointer', flexGrow: 1, textAlign: 'center' }} onClick={() => window.location.href = '/'}>
          <i className="fas fa-home"></i> Exit
        </div>
      </div>

      <h1 style={{ color: '#0078ae', marginBottom: '20px' }}>Your {destinationName} Itinerary!</h1>
      <p>When do you plan to check in and check out?</p>

      <div>
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
        />
      </div>

      {dateError && (
        <div style={{ color: 'red', marginBottom: '10px' }}>
          {dateError}
        </div>
      )}

      {(
        <p>Your stay is {dateDistance} {dateDistance === 1 ? 'night' : 'nights'} long.</p>
      )}

      <button
        onClick={handleNext}
        disabled={dateError}
        style={{
          padding: '10px 20px',
          backgroundColor: dateError ? 'grey' : '#0078ae',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: dateError ? 'not-allowed' : 'pointer',
          marginTop: '20px'
        }}
      >
        Next
      </button>
    </div>
  );
};

export default CheckInCheckOut;
