import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from "react-router-dom";
//import { appName, siteFQDN, dbFQDN, defaultBackgroundColor, customerPortal } from '../config';
import { dbFQDN, basePath, appVersion } from '../config';
import './Category.css';


const Category = () => {
  const { id } = useParams(); // Get the :id from the URL
  const location = useLocation(); // Get the route to determine the category (e.g., park, ride, etc.)
  const [itemsData, setItemsData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const localStorageKey = `itemData_${appVersion}`; // Versioned key for localStorage

  const category = location.pathname.split("/")[1]; // Extract the category (park, ride, etc.)

  const handleCategorySelect = useCallback((parentId) => {
    if (itemsData[parentId]) {
      // If data is already in memory, filter it
      setFilteredResults(itemsData[parentId]);
      return;
    }

    const storedData = JSON.parse(localStorage.getItem(localStorageKey)) || {};

    if (storedData[parentId]) {
      setFilteredResults(
        storedData[parentId]
      );
      setItemsData(prevItemsData => ({ ...prevItemsData, [parentId]: storedData[parentId] }));
      return;
    }

    // Fetch the category data if not in memory or localStorage
    setIsLoading(true);
    fetch(`${dbFQDN}/di/v1/${category}`)
    .then(response => response.json())
    .then(data => {
      const transformedData = {};
      
    transformedData[category] = data.map(item => {
        let keywords = '';
        try {
        const parsedData = item.data || {};
        keywords = parsedData.keywords ? parsedData.keywords.join(', ') : '';
        } catch (error) {
        console.error(`Error processing item data for ${category}:`, error);
        }
        return {
        ...item,
        keywords,
        };
    });
      
    // Set state for itemsData and filteredResults
    setItemsData(transformedData); // Store all categories' data in state
    setFilteredResults(transformedData[parentId]);
      
    // Save the data to localStorage
    localStorage.setItem(localStorageKey, JSON.stringify({ ...storedData, ...transformedData }));
    })
    .catch(error => {
      console.error('Error fetching categories data:', error);
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, [category, itemsData, localStorageKey]);

  useEffect(() => {
    handleCategorySelect(category);
  }, [category, id, handleCategorySelect]); // Reload whenever category or ID changes

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigates to the previous page
  };

  const handleButtonSelect = (categoryID) => {
    navigate(`/${categoryID}`); // Use the correct variable without the $
  };  

  const handleCatSelect = (categoryID, catItem) => {
    navigate(`/${categoryID}/${catItem}`); // Use the correct variable without the $
  };  

  const handleHome = () => {
    navigate('/'); // Navigates to the root page
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontFamily: 'Arial, sans-serif',
    },
    card: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      margin: '10px 0',
      width: '90%',
      maxWidth: '600px',
      textAlign: 'center',
    },
    title: {
      fontSize: '24px',
      marginBottom: '10px',
    },
    subtitle: {
      fontSize: '18px',
      marginTop: '15px',
    },
    content: {
      fontSize: '16px',
      lineHeight: '1.5',
    },
    list: {
      textAlign: 'left',
      margin: '10px 0',
      padding: '0',
      listStyleType: 'none',
    },
    listItem: {
      marginBottom: '5px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '90%',
      maxWidth: '600px',
      marginTop: '20px',
    },
    button: {
      backgroundColor: '#0078ae',
      color: '#fff',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      fontSize: '16px',
      cursor: 'pointer',
      flex: '1',
      margin: '0 5px',
    },
    loadingText: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  };

  const category_data = [
    { id: 'park', name: 'Park', icon: 'fas fa-chess-rook' },
    { id: 'ride', name: 'Rides', icon: 'fas fa-rocket' },
    { id: 'dining', name: 'Dining', icon: 'fas fa-utensils' },
    { id: 'resort', name: 'Resort', icon: 'fas fa-bed' },
    { id: 'travel', name: 'Travel', icon: 'fas fa-bus' },
    { id: 'extra', name: 'Extras', icon: 'fas fa-star' }
  ];

  return (
    <div style={styles.container}>
      {isLoading && !itemsData ? (
        <p style={styles.loadingText}>Loading...</p>
        ) : (
        <>
            <div className="catItem-card-container">
            {filteredResults.map((item) => (
                <div
                key={item.id}
                className="catItem-card-row"
                onClick={() => handleCatSelect(category, item.id)}
                >
                <img
                    className="catItem-card-thumbnail"
                    src={`/c/${basePath}/${category}/${item.id || 'default'}-360x360.webp`}
                    alt={item.comment || `${category} slot image`}
                />
                <div className="catItem-card-content">
                    <h2>{item.name}</h2>
                    <p>{item.comment}</p>
                </div>
                </div>
            ))}
            </div>
        </>
        )}

        {/* Modal for selecting category and items */}
        <div className="modal-select-category">
            <div className="category-buttons">
            {category_data.map((category, index) => (
                <div
                key={index}
                className={`category-button-wrapper`}
                onClick={() => handleButtonSelect(category.id)}
                >
                <button className="category-button" aria-label={`${category.name} Category Button`}>
                    <i className={category.icon}></i>
                </button>
                <div className="category-name">{category.name}</div>
                </div>
            ))}
            </div>
        </div>

      <div style={styles.buttonContainer}>
        <button onClick={handleBack} style={styles.button}>Back</button>
        <button onClick={handleHome} style={styles.button}>Home</button>
      </div>
    </div>
  );
};

export default Category;
