// src/components/PrivacyPolicy.js
import React from 'react';
import { Link } from 'react-router-dom';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container">
            <div className="privacy-content">
                <Link to="/" className="back-button">Back</Link>
                <h1>Privacy Policy</h1>
                <p>
                    Welcome to Ditinerary.com. Your privacy is critically important to us. This Privacy Policy outlines how we handle your personal data and protect your privacy when you use our services.
                </p>
                <h2>Information We Collect</h2>
                <p>
                    We collect information that you provide to us directly, such as when you create an account, subscribe to our newsletter, or fill out a form on our site. This may include your name, email address, and other contact information.
                </p>
                <h2>How We Use Your Information</h2>
                <p>
                    We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to personalize your experience. We do not sell your personal information to third parties.
                </p>
                <h2>Your Rights</h2>
                <p>
                    You have the right to access, update, or delete your personal information. You can do this by logging into your account or contacting us directly.
                </p>
                <h2>Contact Us</h2>
                <p>
                    If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:support@ditinerary.com">support@ditinerary.com</a>.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
