// src/components/TermsAndConditions.js
import React from 'react';
import { Link } from 'react-router-dom';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <div className="terms-content">
                <Link to="/" className="back-button">Back</Link>
                <h1>Terms and Conditions</h1>
                <p>
                    Welcome to Ditinerary.com. These terms and conditions outline the rules and regulations for the use of our website and services.
                </p>
                <h2>Acceptance of Terms</h2>
                <p>
                    By accessing this website, you accept these terms and conditions in full. Do not continue to use Ditinerary.com if you do not agree to all of the terms and conditions stated on this page.
                </p>
                <h2>License to Use</h2>
                <p>
                    Unless otherwise stated, Ditinerary.com and/or its licensors own the intellectual property rights for all material on Ditinerary.com. All intellectual property rights are reserved. You may view and/or print pages from Ditinerary.com for your own personal use subject to restrictions set in these terms and conditions.
                </p>
                <h2>User Content</h2>
                <p>
                    In these terms and conditions, “your user content” means material (including without limitation text, images, audio material, video material, and audio-visual material) that you submit to this website. You grant to Ditinerary.com a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate, and distribute your user content in any existing or future media.
                </p>
                <h2>Limitation of Liability</h2>
                <p>
                    In no event shall Ditinerary.com, nor any of its officers, directors, and employees, be liable to you for anything arising out of or in any way connected with your use of this website, whether such liability is under contract, tort, or otherwise.
                </p>
                <h2>Contact Us</h2>
                <p>
                    If you have any questions or concerns about our Terms and Conditions, please contact us at <a href="mailto:support@ditinerary.com">support@ditinerary.com</a>.
                </p>
            </div>
        </div>
    );
};

export default TermsAndConditions;
