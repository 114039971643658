import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Logout = () => {
  useEffect(() => {
    // Remove the token from cookies and localStorage
    Cookies.remove('token', { path: '/' });
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }, []);

  // Redirect to the login page after logout
  return <Navigate to="/" />;
};

export default Logout;
