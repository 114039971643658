import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SelectResort.css';
import { dbFQDN } from '../config';

const SelectResort = () => {
  const [resorts, setResorts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${dbFQDN}/di/v1/resort`)
      .then(response => response.json())
      .then(data => setResorts(data));
  }, []);

  const handleResortSelect = (resort) => {
    localStorage.setItem('selected_resort', JSON.stringify(resort));
    localStorage.setItem('selected_resort_id', resort.id);  // Save the resort ID
    navigate('/select-room');
  };

  return (
    <div className="select-resort-container" style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
      {/* Top navigation with back arrow, home button, and exit */}
      <div className="top-navigation" style={{ display: 'flex', alignItems: 'center', padding: '10px', backgroundColor: '#0078ae', color: 'white', fontSize: '14px' }}>
        <div className="back-arrow" style={{ cursor: 'pointer', marginRight: '10px' }} onClick={() => window.history.back()}>
          <i className="fas fa-arrow-left"></i> Back
        </div>
        <div className="home-button" style={{ cursor: 'pointer', flexGrow: 1, textAlign: 'center' }} onClick={() => window.location.href = '/'}>
          <i className="fas fa-home"></i> Exit
        </div>
      </div>

      <h1 style={{ color: '#0078ae', marginBottom: '20px' }}>Select a Resort</h1>
      
      <div className="resorts" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {resorts.map(resort => (
          <div
            key={resort.id}
            className="resort-card"
            onClick={() => handleResortSelect(resort)}
            style={{
              margin: '10px',
              padding: '15px',
              border: '1px solid #0078ae',
              borderRadius: '10px',
              cursor: 'pointer',
              width: '200px',
              textAlign: 'center',
              backgroundColor: 'white',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <p style={{ margin: '0', fontWeight: 'bold' }}>{resort.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectResort;
