import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GettingStarted.css';

const GettingStarted = () => {
  const navigate = useNavigate();

  const destinations = [
    { key: 'wdw', name: 'Walt Disney World' },
    { key: 'dlr', name: 'Disneyland Resort' },
    { key: 'uo', name: 'Universal Orlando' }
  ];
  
  const handleSelection = (selection) => {
    // Find the selected destination from the array
    const selectedDestination = destinations.find(dest => dest.key === selection);
  
    if (selectedDestination) {
      // Store the destination name in localStorage
      localStorage.setItem('destinationName', selectedDestination.name);
      localStorage.setItem('selection', selection);
      // Navigate to the next page
      navigate('/check-in-check-out');
    }
  };
  

  const isDesktop = window.innerWidth >= 769;

  return (
    <div className="getting-started-container" style={{ padding: isDesktop ? '20px' : '10px' }}>
      {/* Top navigation with back arrow, home button, and exit */}
      <div className="top-navigation" style={{ 
        display: 'flex', 
        alignItems: 'center', 
        padding: isDesktop ? '10px' : '5px', 
        backgroundColor: '#0078ae', 
        color: 'white', 
        fontSize: isDesktop ? '18px' : '14px' 
      }}>
        <div className="back-arrow" style={{ 
          cursor: 'pointer', 
          marginRight: '10px', 
          fontSize: isDesktop ? '18px' : '14px' 
        }} onClick={() => window.history.back()}>
          <i className="fas fa-arrow-left"></i> Back
        </div>
        <div className="home-button" style={{ 
          cursor: 'pointer', 
          flexGrow: 1, 
          textAlign: 'center', 
          fontSize: isDesktop ? '18px' : '14px' 
        }} onClick={() => window.location.href = '/'}>
          <i className="fas fa-home"></i> Exit
        </div>
      </div>

      {/* Main Content */}
      <h1 style={{ 
        color: '#0078ae', 
        textAlign: 'center', 
        margin: isDesktop ? '20px 0' : '10px 0', 
        fontSize: isDesktop ? '32px' : '24px' 
      }}>
        Welcome to Ditinerary
      </h1>
      <p>Please choose a destination to start creating your itinerary.</p>
      <div className="options" style={{ 
        display: 'flex', 
        flexDirection: isDesktop ? 'row' : 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        gap: '20px', 
        textAlign: 'center' 
      }}>
        {/* Disney World Option */}
        <div className="option" onClick={() => handleSelection('wdw')} style={{ 
          cursor: 'pointer', 
          marginBottom: isDesktop ? '0' : '20px' 
        }}>
          <img src="/c/OGERPVM/park/epcot-360x360.webp" alt="Disney World" style={{ 
            width: isDesktop ? '150px' : '100px', 
            height: isDesktop ? '150px' : '100px', 
            objectFit: 'cover' 
          }} />
          <p style={{ marginTop: '10px', fontSize: isDesktop ? '16px' : '14px' }}>Disney World</p>
        </div>

        {/* Disneyland Resort Option (Disabled) */}
        <div className="option disabled" style={{ opacity: 0.5 }}>
          <img src="/assets/images/space-360x360.webp" alt="Disneyland Resort" style={{ 
            width: isDesktop ? '150px' : '100px', 
            height: isDesktop ? '150px' : '100px', 
            objectFit: 'cover' 
          }} />
          <p style={{ marginTop: '10px', fontSize: isDesktop ? '16px' : '14px' }}>Disneyland Resort</p>
        </div>

        {/* Universal Studios Orlando Option (Disabled) */}
        <div className="option disabled" style={{ opacity: 0.5 }}>
          <img src="/assets/images/space-360x360.webp" alt="Universal Studios Orlando" style={{ 
            width: isDesktop ? '150px' : '100px', 
            height: isDesktop ? '150px' : '100px', 
            objectFit: 'cover' 
          }} />
          <p style={{ marginTop: '10px', fontSize: isDesktop ? '16px' : '14px' }}>Universal Studios Orlando</p>
        </div>
      </div>
    </div>
  );
};

export default GettingStarted;
