import { addDays, format } from 'date-fns';

// Original data array (matrix)
const originalData = [
    [{
        "id" : "OT6733",
        "name" : null,
        "comment" : "Magic Kingdom Opens!",
        "parent_id" : "park",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "magic_kingdom",
        "startTime" : "08:00:00",
        "endTime" : "23:45:00"
    },
    {
        "id" : "QJKQC2",
        "name" : null,
        "comment" : "1900 Park Fare",
        "parent_id" : "dining",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "1900_park_fare",
        "startTime" : "08:30:00",
        "endTime" : "23:45:00"
    },{
        "id" : "B5UQFJ",
        "name" : null,
        "comment" : "Jungle Cruise",
        "parent_id" : "ride",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "jungle_cruise",
        "startTime" : "10:15:00",
        "endTime" : "23:45:00"
    },
    {
        "id" : "9SQT32",
        "name" : null,
        "comment" : "Monorail",
        "parent_id" : "travel",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "monorail",
        "startTime" : "08:15:00",
        "endTime" : "23:45:00"
    },{
        "id" : "NFWDJ6",
        "name" : null,
        "comment" : "Buzz's Space Ranger Spin",
        "parent_id" : "ride",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "buzz_lightyears_space_ranger_spin",
        "startTime" : "13:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "XL4NML",
        "name" : null,
        "comment" : "Skipper Canteen",
        "parent_id" : "dining",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "skipper_canteen",
        "startTime" : "12:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "PDZQX5",
        "name" : null,
        "comment" : "Magic Kingdom Closes!",
        "parent_id" : "park",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "magic_kingdom",
        "startTime" : "22:30:00",
        "endTime" : "23:45:00"
    },{
        "id" : "LUG6W7",
        "name" : null,
        "comment" : "Find a Spot for Parade",
        "parent_id" : "note",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : null,
        "startTime" : "14:30:00",
        "endTime" : "23:45:00"
    },{
        "id" : "06VEMH",
        "name" : null,
        "comment" : "Find a spot for Fireworks!",
        "parent_id" : "note",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : null,
        "startTime" : "19:45:00",
        "endTime" : "23:45:00"
    }],
    [{
        "id" : "UU7KZT",
        "name" : null,
        "comment" : "‘Ohana - Lilo & Stitch Breakfast",
        "parent_id" : "dining",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "ohana",
        "startTime" : "08:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "71K60M",
        "name" : null,
        "comment" : "Epcot Opens!",
        "parent_id" : "park",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "epcot",
        "startTime" : "09:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "C6FIB5",
        "name" : null,
        "comment" : "Spaceship Earth",
        "parent_id" : "ride",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "spaceship_earth",
        "startTime" : "10:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "40U1VF",
        "name" : null,
        "comment" : "Living with the Land",
        "parent_id" : "ride",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "living_with_the_land",
        "startTime" : "10:30:00",
        "endTime" : "23:45:00"
    },{
        "id" : "9TGR9E",
        "name" : null,
        "comment" : "The Seas with Nemo & Friends",
        "parent_id" : "ride",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "the_seas_with_nemo_and_friends",
        "startTime" : "10:15:00",
        "endTime" : "23:45:00"
    },{
        "id" : "EGZAPS",
        "name" : null,
        "comment" : "Soarin' Around the World",
        "parent_id" : "ride",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "soarin_around_the_world",
        "startTime" : "11:45:00",
        "endTime" : "23:45:00"
    },{
        "id" : "2OHVXZ",
        "name" : null,
        "comment" : "Garden Grill Restaurant",
        "parent_id" : "dining",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "garden_grill_restaurant",
        "startTime" : "12:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "CM7Z9Q",
        "name" : null,
        "comment" : "Journey Into Imagination",
        "parent_id" : "ride",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "journey_into_imagination_with_figment",
        "startTime" : "12:30:00",
        "endTime" : "23:45:00"
    },{
        "id" : "V52XQ3",
        "name" : null,
        "comment" : "Epcot Closes!",
        "parent_id" : "park",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "epcot",
        "startTime" : "23:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "6Y1QQR",
        "name" : null,
        "comment" : "Friendship Boats to Morocco",
        "parent_id" : "travel",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "friendship_boats",
        "startTime" : "12:45:00",
        "endTime" : "23:45:00"
    }],
    [{
        "id" : "2HO13N",
        "name" : null,
        "comment" : "Hollywood Studios Opens!",
        "parent_id" : "park",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "disneys_hollywood_studios",
        "startTime" : "08:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "PJ68WE",
        "name" : null,
        "comment" : "Skyliner!!!",
        "parent_id" : "travel",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "skyliner",
        "startTime" : "07:45:00",
        "endTime" : "23:45:00"
    },{
        "id" : "OA5JNZ",
        "name" : null,
        "comment" : "Rise of the Resistance",
        "parent_id" : "ride",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "star_wars_rise_of_the_resistance",
        "startTime" : "08:45:00",
        "endTime" : "23:45:00"
    },{
        "id" : "S4HTNZ",
        "name" : null,
        "comment" : "Slinky Dog Dash",
        "parent_id" : "ride",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "slinky_dog_dash",
        "startTime" : "10:30:00",
        "endTime" : "23:45:00"
    },{
        "id" : "335WDY",
        "name" : null,
        "comment" : "Woody’s Roundup BBQ",
        "parent_id" : "dining",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "woodys_roundup_bbq",
        "startTime" : "12:00:00",
        "endTime" : "23:45:00"
    }],
    [{
        "id" : "5FNIP8",
        "name" : null,
        "comment" : "Animal Kingdom Opens!",
        "parent_id" : "park",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "disneys_animal_kingdom",
        "startTime" : "07:30:00",
        "endTime" : "23:45:00"
    },{
        "id" : "OLNVSK",
        "name" : null,
        "comment" : "Animal Kingdom Closes!",
        "parent_id" : "park",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "disneys_animal_kingdom",
        "startTime" : "17:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "1M2SQH",
        "name" : null,
        "comment" : "Tusker House :)",
        "parent_id" : "dining",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "tusker_house_restaurant",
        "startTime" : "12:00:00",
        "endTime" : "23:45:00"
    }],
    [{
        "id" : "CH0503",
        "name" : null,
        "comment" : "Disney Springs",
        "parent_id" : "note",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : null,
        "startTime" : "10:00:00",
        "endTime" : "23:45:00"
    },{
        "id" : "56HFZO",
        "name" : null,
        "comment" : "Bus to Airport!",
        "parent_id" : "travel",
        "data" : null,
        "itinerary_id" : "WVFZKQ",
        "child_id" : "bus",
        "startTime" : "12:00:00",
        "endTime" : "23:45:00"
    }]
];

// Function to generate new itinerary items
const generateNewItineraryItems = (daysOffset = 1) => {
    const items = [];

    // Flatten the matrix and generate new items
    originalData.forEach((subArray, outerIndex) => {
        if (Array.isArray(subArray)) {
            subArray.forEach((item) => {
                const newStartDate = addDays(new Date(), daysOffset + outerIndex);
                const newEndDate = addDays(new Date(), daysOffset + outerIndex);
                const startTime = item.startTime;
                const endTime = item.endTime;

                const newItem = {
                    ...item,
                    startTime: format(newStartDate, "yyyy-MM-dd'T'") + startTime,
                    endTime: format(newEndDate, "yyyy-MM-dd'T'") + endTime
                };

                items.push(newItem);
            });
        } else {
            console.warn("Expected an array but found:", subArray);
        }
    });

    // Return a single JSON object with items
    return items;
};

export default generateNewItineraryItems;
