import React, { useState, useEffect } from 'react';

const Socialshare = () => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const imageUrl = '/api/dynamic-image?text=Hello+World';
        setImageUrl(imageUrl); // Backend serves this URL
    }, []);

    return (
        <div>
            <h1>Dynamic Social Share Image</h1>
            <img src={imageUrl} alt="Social Share" />
        </div>
    );
};

export default Socialshare;
